import img1 from "../Img/1.png"
import img2 from "../Img/2.png"
import img3 from "../Img/3.png"
import img4 from "../Img/4.png"
import img5 from "../Img/5.png"
import img6 from "../Img/6.jpg"
import img7 from "../Img/7.jpg"
import img8 from "../Img/8.jpg"
import img10 from "../Img/10.jpg"
import img11 from "../Img/11.jpg"
import img12 from "../Img/12.jpg"
import img13 from "../Img/13.jpg"

import img14 from "../Img/14.jpg"
import img15 from "../Img/15.jpg"
import img16 from "../Img/16.jpg"
import img17 from "../Img/17.jpg"
import img18 from "../Img/18.jpg"
import img19 from "../Img/19.jpg"
import img20 from "../Img/20.jpg"
import img21 from "../Img/21.jpg"
import img22 from "../Img/22.jpg"
import img23 from "../Img/23.jpg"
import img24 from "../Img/24.jpg"
import img25 from "../Img/25.jpg"
import img26 from "../Img/26.jpg"
import img27 from "../Img/27.jpg"
import img28 from "../Img/28.jpg"

import img30 from "../Img/30.jpg"

import img32 from "../Img/32.jpg"
import img33 from "../Img/33.jpg"
import img34 from "../Img/34.jpg"
import img35 from "../Img/35.jpg"
import img36 from "../Img/36.jpg"
import img37 from "../Img/37.jpg"
import img38 from "../Img/38.jpg"
import img39 from "../Img/39.jpg"
import img40 from "../Img/40.jpg"
// import img41 from "../Img/41.jpg"
// import img42 from "../Img/42.jpg"
// import img43 from "../Img/43.jpg"
// import img44 from "../Img/44.jpg"
// import img45 from "../Img/45.jpg"
// import img46 from "../Img/46.jpg"
// import img47 from "../Img/47.jpg"
// import img48 from "../Img/48.jpg"
// import img49 from "../Img/49.jpg"
// import img50 from "../Img/50.jpg"
// import img51 from "../Img/51.jpg"
// import img52 from "../Img/52.jpg"
// import img53 from "../Img/53.jpg"
// import img54 from "../Img/54.jpg"
// import img55 from "../Img/55.jpg"
// import img56 from "../Img/56.jpg"
// import deleteIcon from "../../Component/Lib/Svgs/Delete.jsx"




const foods = [{
        id: 1,
        img: "https://cdn.delever.uz/delever/d4db4b28-c365-47ca-8509-0557473f4880",
        title: "Spicy seasoned seafood noodles",
        price: 2.29,
        available: 20,
        type: "hot-dishes",

    },
    {
        id: 2,
        img: "https://cdn.delever.uz/delever/d4db4b28-c365-47ca-8509-0557473f4880",
        title: "  x owls available",
        price: 3.29,
        available: 2,
        type: "hot-dishes"
    },
    {
        id: 3,
        img: "https://cdn.delever.uz/delever/d4db4b28-c365-47ca-8509-0557473f4880",
        title: "Salted Pasta with mushroom sauce",
        price: 5.29,
        available: 17,
        type: "hot-dishes"
    }, {
        id: 4,
        img: "https://cdn.delever.uz/delever/d4db4b28-c365-47ca-8509-0557473f4880",
        title: "Beef dumpling in hot soup",
        price: 3.29,
        available: 6,
        type: "hot-dishes"
    }, {
        id: 5,
        img: "https://cdn.delever.uz/delever/d4db4b28-c365-47ca-8509-0557473f4880",
        title: "Healthy noodle with spinach leaf",
        price: 2.29,
        available: 12,
        type: "Hot Dishes"
    }, {
        id: 6,
        img: img6,
        title: "Hot spicy fried rice with omelet",
        price: 9.29,
        available: 14,
        type: "hot-dishes"
    }, {
        id: 7,
        img: img7,
        title: "Spicy instant noodle with ",
        price: 2.29,
        available: 23,
        type: "hot-dishes"
    }, {
        id: 8,
        img: img8,
        title: " constant noodle  special omelette",
        price: 2.9,
        available: 8,
        type: "hot-dishes"
    }, {
        id: 9,
        img: img1,
        title: " noodle with spinach",
        price: 0.29,
        available: 7,
        type: "hot-dishes"
    },






    //  *********************************
    {
        id: 10,
        img: img10,
        title: "Spicy seasoned seafood noodles",
        price: 2.29,
        available: 20,
        type: "cold_dishes"
    },
    {
        id: 11,
        img: img11,
        title: "  x owls available",
        price: 3.29,
        available: 2,
        type: "cold_dishes"
    },
    {
        id: 12,
        img: img12,
        title: "Salted Pasta with mushroom sauce",
        price: 5.29,
        available: 17,
        type: "cold_dishes"
    }, {
        id: 13,
        img: img13,
        title: "Beef dumpling in hot soup",
        price: 3.29,
        available: 6,
        type: "cold_dishes"
    }, {
        id: 14,
        img: img5,
        title: "Healthy noodle with spinach leaf",
        price: 2.29,
        available: 12,
        type: "cold_dishes"
    }, {
        id: 15,
        img: img13,
        title: "Hot spicy fried rice with omelet",
        price: 9.29,
        available: 14,
        type: "cold_dishes"
    }, {
        id: 16,
        img: img14,
        title: "Spicy instant noodle with ",
        price: 2.29,
        available: 23,
        type: "cold_dishes"
    }, {
        id: 17,
        img: img15,
        title: " constant noodle  special omelette",
        price: 2.9,
        available: 8,
        type: "cold_dishes"
    }, {
        id: 18,
        img: img16,
        title: " noodle with spinach",
        price: 0.29,
        available: 7,
        type: "soup"
    },






    //  *********************************

    {
        id: 19,
        img: img17,
        title: "Spicy seasoned seafood noodles",
        price: 2.29,
        available: 20,
        type: "soup"
    },
    {
        id: 20,
        img: img18,
        title: "  x owls available",
        price: 3.29,
        available: 2,
        type: "soup"
    },
    {
        id: 21,
        img: img19,
        title: "Salted Pasta with mushroom sauce",
        price: 5.29,
        available: 17,
        type: "soup"
    }, {
        id: 22,
        img: img20,
        title: "Beef dumpling in hot soup",
        price: 3.29,
        available: 6,
        type: "soup"
    }, {
        id: 23,
        img: img21,
        title: "Healthy noodle with spinach leaf",
        price: 2.29,
        available: 12,
        type: "soup"
    }, {
        id: 24,
        img: img22,
        title: "Hot spicy fried rice with omelet",
        price: 9.29,
        available: 14,
        type: "soup"
    }, {
        id: 25,
        img: img23,
        title: "Spicy instant noodle with ",
        price: 2.29,
        available: 23,
        type: "soup"
    }, {
        id: 26,
        img: img24,
        title: " constant noodle  special omelette",
        price: 2.9,
        available: 8,
        type: "soup"
    }, {
        id: 27,
        img: img25,
        title: " noodle with spinach",
        price: 0.29,
        available: 7,
        type: "grill"
    },
    {
        id: 28,
        img: img26,
        title: "Spicy seasoned seafood noodles",
        price: 2.29,
        available: 20,
        type: "grill"
    },
    {
        id: 29,
        img: img27,
        title: "  x owls available",
        price: 3.29,
        available: 2,
        type: "grill"
    },
    {
        id: 30,
        img: img28,
        title: "Salted Pasta with mushroom sauce",
        price: 5.29,
        available: 17,
        type: "grill"
    }, {
        id: 31,
        img: img25,
        title: "Beef dumpling in hot soup",
        price: 3.29,
        available: 6,
        type: "grill"
    }, {
        id: 32,
        img: img30,
        title: "Healthy noodle with spinach leaf",
        price: 2.29,
        available: 12,
        type: "cold_dishes"
    }, {
        id: 33,
        img: img35,
        title: "Hot spicy fried rice with omelet",
        price: 9.29,
        available: 14,
        type: "appetizer"
    }, {
        id: 34,
        img: img32,
        title: "Spicy instant noodle with ",
        price: 2.29,
        available: 23,
        type: "appetizer"
    }, {
        id: 35,
        img: img33,
        title: " constant noodle  special omelette",
        price: 2.9,
        available: 8,
        type: "appetizer"
    }, {
        id: 36,
        img: img34,
        title: " noodle with spinach",
        price: 0.29,
        available: 7,
        type: "appetizer"
    }, {
        id: 37,
        img: img35,
        title: "Spicy seasoned seafood noodles",
        price: 2.29,
        available: 20,
        type: "appetizer"
    },
    {
        id: 38,
        img: img36,
        title: "  x owls available",
        price: 3.29,
        available: 2,
        type: "desert"
    },
    {
        id: 39,
        img: img37,
        title: "Salted Pasta with mushroom sauce",
        price: 5.29,
        available: 17,
        type: "desert"
    }, {
        id: 40,
        img: img38,
        title: "Beef dumpling in hot soup",
        price: 3.29,
        available: 6,
        type: "desert"
    }, {
        id: 41,
        img: img39,
        title: "Healthy noodle with spinach leaf",
        price: 2.29,
        available: 12,
        type: "desert"
    }, {
        id: 42,
        img: img40,
        title: "Hot spicy fried rice with omelet",
        price: 9.29,
        available: 14,
        type: "desert"
    },
    {
        id: 43,
        img: img26,
        title: "Beef dumpling in hot soup",
        price: 3.29,
        available: 6,
        type: "grill"
    },
    {
        id: 44,
        img: img3,
        title: "Salted Pasta with mushroom sauce",
        price: 5.29,
        available: 17,
        type: "hot-dishes"
    },
    {
        id: 45,
        img: img32,
        title: "Salted Pasta with mushroom sauce",
        price: 5.29,
        available: 17,
        type: "appetizer"
    },
    {
        id: 46,
        img: img37,
        title: "Salted Pasta with mushroom sauce",
        price: 5.29,
        available: 17,
        type: "desert"
    },
]


const filialData = [
    {
        id:1,
        title: "MaxWay Magic City 2",
        location:"Magic City,ул. Бабура, 1, Ташкент, Узбекистан",
        date: "10:00-03:00",
    },
    {
        id:2,
        title: "MaxWay Magic City 3",
        location:"Magic City,ул. Бабура, 74, Ташкент, Узбекистан",
        date: "10:00-01:00",
    },
    {
        id:3,
        title: "MaxWay Magic City 4",
        location:"Magic City,ул. Бабура, 14, Ташкент, Узбекистан",
        date: "10:00-03:00",
    },
    {
        id:4,
        title: "MaxWay Magic City 5",
        location:"Magic City,ул. Бабура, 4, Ташкент, Узбекистан",
        date: "10:00-01:00",
    },
    {
        id:5,
        title: "MaxWay Magic City 6",
        location:"Magic City,ул. Бабура, 24, Ташкент, Узбекистан",
        date: "10:00-03:00",
    },
    {
        id:6,
        title: "MaxWay Magic City 7",
        location:"Magic City,ул. Бабура, 2, Ташкент, Узбекистан",
        date: "10:00-03:00",
    },
       {
        id:7,
        title: "MaxWay Magic City 7",
        location:"Magic City,ул. Бабура, 99, Ташкент, Узбекистан",
        date: "10:00-03:00",
    },
    {
        id:8,
        title: "MaxWay Magic City 8",
        location:"Magic City,ул. Бабура, 55, Ташкент, Узбекистан",
        date: "10:00-02:00",
    }
]
export default foods;