import FilialCard from "../../Components/FilialCard/FilialCard";
import "./FilialCardPage.css"

const FilialCardPage = () =>{
     return (
         <div className="filial-cards">
             <div className="container">
                 <FilialCard />
             </div>

         </div>
     )
}

export default FilialCardPage;